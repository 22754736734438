import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import MessagePop from '../../assets/MessagePop';
import DataInHumidifier from './DataInHumidifier';
import { toast } from "react-toastify";

const PlaceNewPiecesInOven = () => {
    const location = useLocation();

    const [humidifier] = useState(location.state.humidifier);
    const [humidifierNo] = useState(location.state.humidifierNo);
    const [dataId] = useState(location.state.dataId);
    const [smFgId, setSmFgId] = useState('');
    const [newIdInfo, setNewIdInfo] = useState({
        humidifierNo: humidifierNo,
        smFgIds: [],
    });



    const addInList = (id) => {
        if (id.startsWith('DM') && id.length >= 8) {
            const alreadyPresentInList = newIdInfo.smFgIds.indexOf(id);

            if (alreadyPresentInList > -1) {
                alertMessage(`Can't add! You already scanned this ID: ${newIdInfo.smFgIds[alreadyPresentInList]}`);
                setSmFgId('');
            } else {
                setNewIdInfo((prevState) => ({
                    ...prevState,
                    smFgIds: [...prevState.smFgIds, id],
                }));
                setSmFgId('');
                inputRef.current.focus();
            }
        } else if (id.length >= 8) {
            alertMessage(`Can't add! ID must start with "DM".`);
        } else if (id.length < 8) {
            alertMessage(`Can't add! ID must at least of 8 character.`);
        }
    };

    //! Start: Message Thing
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const inputRef = useRef();

    const alertMessage = (message) => {
        setModalMessage(message);
        setIsModalOpen(true);
        inputRef.current.blur();

    };
    //! End: Message Thing

    const remove = (index) => {
        const removeIdsArray = newIdInfo.smFgIds.filter((_, i) => i !== index);
        setNewIdInfo((prevState) => ({
            ...prevState,
            smFgIds: [...removeIdsArray],
        }));
    };

    const submitEnteredData = async () => {
        try {
            const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
            const url = `${BACK_HOST}api/v1/humidifier/placePieces`;
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newIdInfo),
                credentials: 'include'
            })
            if (!res.ok) {
                const errorData = await res.json();
                alertMessage(`Error: ${errorData.message}`);
                return errorData

            }
            const data = await res.json();
            toast.success(`${data.message}`);
            setNewIdInfo((prev) => ({ ...prev, smFgIds: [] }))
            return data;

        } catch (error) {
            console.error('Error adding mould:', error);
        }
    };

    return (
        <>
            <MessagePop
                isOpen={isModalOpen}
                message={modalMessage}
                onClose={() => setIsModalOpen(false)}
            />

            <div className="max-w-lg sm:mx-auto shadow-2xl rounded p-6 my-12 bg-white mx-2">
                <h2 className="font-bold text-sm text-center mb-4">
                    Humidifier: {newIdInfo.humidifierNo}  <br /> 
                </h2>

                <span className='mt-2 text-sm font-semibold'>Enter Piece Id:</span>
                <div className="flex mb-4 ">

                    <input
                        name="mouldIds"
                        type="text"
                        value={smFgId || ''}
                        onChange={(e) => {
                            const value = e.target.value.trim();
                            setSmFgId(value);
                            if (value.length >= 8) {
                                addInList(value);
                            }
                        }}
                        ref={inputRef}
                        className=" border border-black-900 text-black text-sm rounded-lg block w-full p-2.5"
                        placeholder="Enter Mould ID (DM0012)"
                    />
                    <button
                        type="button"
                        onClick={() => addInList(smFgId)}
                        className="ml-2 bg-teal-700 text-white font-medium text-sm px-4 py-2 rounded-lg hover:bg-teal-800"
                    >
                        Add
                    </button>
                </div>

                <div className="mb-4">
                    <h3 className="font-semibold">Semi-Finished Goods IDs:</h3>
                    <div className="flex flex-wrap gap-2 mt-2">
                        {newIdInfo.smFgIds.map((id, index) => (
                            <div key={index} className="flex items-center bg-yellow-200 px-3 py-1 rounded-full">
                                <span className="mr-2">{id}</span>
                                <button
                                    onClick={() => remove(index)}
                                    className="text-red-600 bg-gray-200 px-2 rounded-full hover:bg-gray-300"
                                >
                                    ×
                                </button>
                            </div>
                        ))}
                    </div>
                </div>

                <button
                    type="button"
                    onClick={() => submitEnteredData(newIdInfo)}
                    className="w-full bg-teal-700 text-white font-medium text-sm px-4 py-2 rounded-lg hover:bg-teal-800"
                >
                    Submit
                </button>
            </div>
            <DataInHumidifier dataId={dataId} humidifier={humidifier}/>
        </>
    );
};

export default PlaceNewPiecesInOven;
